<script setup lang="ts">
  import useCpBem from '~/composables/useCpBem';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import type {ICpPillType} from '~/composables/useCpType';

  withDefaults(defineProps<{
    short?: boolean,
    kind?: 'standard'|'discount',
    variant: ICpPillType,
    discount?: string | boolean,
    amount?: string,
    back?: boolean,
  }>(), {
    kind: 'standard',
    variant: 'featuredProduct',
  });

  const {b, e, em} = useCpBem('cp-pill-card');
  // TODO: Add texts to translations
</script>

<template>
    <div :class="b">
        <div v-if="variant === 'offer-discount'" :class="em('container', { mkt: true, left: true, 'small-padding': true })">
            <CpText type="white" variant="caption-bold">
                -{{ discount }}%
            </CpText>
        </div>
        <div v-else-if="variant ==='featured-short'" :class="em('container', { mkt: true, left: true, 'feat-short': true })">
            <CpIconStandard kind="small" icon="magic_button" type="white" />
        </div>
        <div v-else-if="variant ==='featured-large'" :class="em('container', { mkt: true, left: true })">
            <CpText type="white" variant="caption-bold">
                Destacado
            </CpText>
            <CpIconStandard kind="small" icon="magic_button" type="white" />
        </div>
        <div v-else-if="variant === 'offer-label'" :class="em('container', { mkt: true, right: true })">
            <CpText type="white" variant="caption-bold">
                Oferta
            </CpText>
        </div>
        <div v-else-if="variant === 'featured-offer'" :class="em('container', { mkt: true, left: true })">
            <CpText type="white" variant="caption-bold">
                -{{ discount }}%
            </CpText>
            <CpIconStandard kind="small" icon="magic_button" type="white" />
        </div>
        <div v-else-if="variant === 'Oos'" :class="em('container', { 'neutral-extra-light': true, left: true })">
            <CpText type="white" variant="caption-bold">
                Agotado
            </CpText>
        </div>
        <div v-else-if="variant ==='new-short'" :class="em('container', { primary: true, left: true, 'feat-short': true })">
            <CpIconStandard kind="small" icon="new_releases" type="white" />
        </div>
        <div v-else-if="variant ==='new-large'" :class="em('container', { primary: true, left: true })">
            <CpText type="white" variant="caption-bold">
                Nuevo
            </CpText>
            <CpIconStandard kind="small" icon="new_releases" type="white" />
        </div>

        <div v-else-if="variant ==='featuredProduct'" :class="em('container', {mkt: true, standard: true})">
            <CpText v-if="kind==='standard' && !short" type="white" variant="caption-bold">
                Destacado
            </CpText>
            <CpText v-if="kind==='discount'" type="white" variant="caption-bold">
                -{{ discount }}%
            </CpText>
            <CpIconStandard kind="small" icon="magic_button" type="white" />
        </div>
        <div v-if="variant === 'offer'" :class="em('container', {mkt: true, standard: true})">
            <CpText :class="e`discount`" type="white" variant="caption-bold">
                -{{ discount }}%
            </CpText>
        </div>
        <div v-else-if="variant === 'gift'" :class="em('container', {mkt: true, standard: true})">
            <CpText type="white" variant="caption-bold">
                De regalo
            </CpText>
            <CpIconStandard kind="small" icon="card_giftcard" type="white" />
        </div>
        <div v-else-if="variant === 'esd'" :class="em('container', {primary: true, standard: true, esdDisplay: short})">
            <CpText v-if="!short" type="white" variant="caption-bold">
                Descargable
            </CpText>
            <CpIconStandard kind="small" icon="download" type="white" :class="em('extra-width', {'back': back})" />
        </div>
        <div v-else-if="variant === 'oosEol'" :class="em('container', {neutral: true, standard: true})">
            <CpText type="white" variant="caption-bold">
                Agotado
            </CpText>
        </div>
        <div v-else-if="variant === 'eol'" :class="em('container', {white: true, rounded: true})">
            <CpText type="neutral" variant="caption-bold">
                Quizá fuera del mercado
            </CpText>
        </div>
        <div v-else-if="variant === 'extendedTime'" :class="em('container', {white: true, rounded: true})">
            <CpText type="neutral" variant="caption-bold">
                Tiempo de entrega prolongado
            </CpText>
        </div>
        <div v-else-if="variant === 'bundle'" :class="em('container', {mkt: true, rounded: true})">
            <CpText type="white" variant="caption-bold">
                Descuento por bundle ${{ amount }}
            </CpText>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .cp-pill-card {
      width: fit-content;
      height: 20px;
      display: flex;
      &__container {
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        padding: 2px 8px;
        border: var(--border);
        &--mkt {
          background: var(--color-mkt);
        }
        &--primary {
          background: var(--color-primary);
        }
        &--neutral {
          background: var(--color-neutral);
          padding: 4px 8px;
        }
        &--neutral-extra-light {
          background: var(--color-neutral-extra-light);
          padding: 4px 8px;
        }
        &--white {
          background: var(--color-white);
          border: 1px solid var(--color-neutral);
        }
        &--standard {
          border-radius: 8px 0;
        }
        &--rounded {
          border-radius: 16px;
          padding: 4px 8px;
        }
        &--right {
          border-radius: 0 0 0 8px;
        }
        &--left {
          border-radius: 0 0 8px 0;
        }
        &--esd-display {
          justify-content: flex-end;
        }
        &--small-padding {
          padding: 4px 8px;
        }
        &--feat-short {
          padding: 4px 8px 4px 16px;
        }
      }
      &__extra-width {
        &--back {
          padding: 0 $cp-gutter-small 0 $cp-gutter;
        }
      }
      &__discount {
        padding: 2px;
      }
    }
</style>
